@use "./node_modules/include-media/dist/_include-media.scss" as *;
.general {
    display: flex;
    align-items: flex-start;
    gap: 2rem;
    padding-bottom: 2rem;

    @include media('<tablet') {
        flex-direction: column-reverse;
        gap: 1rem;
    }
}

.media {
    position: relative;
    flex-basis: 50%;
    flex-shrink: 0;
}

.figcaption {
    font-family: var(--font-sans);
    font-size: 0.875rem;
    text-align: center;
    transition: transform var(--transition-duration);
    padding-top: 0.5rem;

    :global(*) {
        font-size: inherit !important;
        line-height: 1.4 !important;
    }
}

.content {
    flex-basis: 50%;
    flex-grow: 0;
    overflow: hidden;
}

.image {
    display: block;
    width: 100%;
    object-fit: contain;
    opacity: 0;
    transition: all var(--transition-duration);
    height: auto;
    max-height: min(
        80vh,
        608px
    ); // 608px => max-width of container, so that one format in landscape or portrait would be the same size

    .imageLoaded & {
        opacity: 1;
    }
}

.swiperOuter {
    :global(.swiper-wrapper) {
        align-items: center;
    }
}

.imageLink {
    position: relative;
    display: block;
    transition: all var(--transition-duration);
    background: rgba(0 0 0 / 20%);
    cursor: pointer;

    &:hover {
        transform: scale(1.02);

        + .figcaption {
            transform: translateY(0.5rem);
        }
    }

    &.imageLoaded {
        background: rgba(0 0 0 / 0%);
    }
}

.swiperLink {
    position: relative;
    display: grid;
    place-content: center;
    transition: all var(--transition-duration);
    background: rgba(0 0 0 / 20%);

    &:hover {
        transform: scale(1.02);
    }
}

.swiperImage {
    display: block;
}

.spinner {
    position: absolute;
    left: 50%;
    top: 50%;
    animation: spin 0.3s infinite linear;
}

@keyframes spin {
    0% {
        transform: translate(-50%, -50%) rotate(0deg);
    }

    100% {
        transform: translate(-50%, -50%) rotate(359deg);
    }
}

.pr1 {
    padding-right: 1rem;
}

.mb1 {
    margin-bottom: 1rem;
}

.noWrap {
    white-space: nowrap;
}

.prices {
    border-collapse: collapse;

    th {
        font-weight: normal;
        text-align: left;
        border-bottom: 1px solid rgba(var(--textcolor-rgb) / 33%);
    }

    td {
        border-bottom: 1px solid rgba(var(--textcolor-rgb) / 10%);
    }

    th,
    td {
        padding: 0.75rem;
        vertical-align: baseline;

        &:first-child {
            padding-left: 0;
        }

        &:last-child {
            padding-right: 0;
        }
    }

    tr:last-child td {
        border-bottom: none;
    }
}

.simpleList {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;

    li {
        display: flex;
        align-items: center;
        gap: 0.5rem;
    }

    a {
        color: rgb(var(--linkcolor-rgb));
        text-decoration: underline;
        text-decoration-color: rgba(var(--linkcolor-rgb) / 33%);
        transition: text-decoration-color var(--transition-duration);

        &:hover {
            text-decoration-color: rgba(var(--linkcolor-rgb) / 100%);
        }
    }
}

.hex {
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    border: 2px solid rgba(var(--textcolor-rgb) / 15%);
}

.swiperCtrls {
    display: flex;
    justify-content: space-between;
}

.swiperDots {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.swiperDot {
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    border: 0.25rem solid transparent;
    background: rgba(255 255 255 / 20%);
    background-clip: padding-box;
    cursor: pointer;

    &:hover {
        background-color: rgba(255 255 255 / 100%);
    }
}

.swiperDotActive {
    background-color: white;
}

.swiperBtn {
    padding: 0.25rem;
    display: grid;
    place-content: center;
    transition: transform var(--transition-duration);

    &:hover {
        transform: scale(1.05);
    }
}

.swiper {
    :global(.swiper-slide) {
        width: auto;
    }
}

.swiperEmptySlide {
    aspect-ratio: 1 / 1;
    background: rgba(255 255 255 / 10%);
    height: 160px;
    display: grid;
    overflow: hidden;
    place-content: center;
    text-align: center;
    font-size: 0.875rem;
}

.swiperHeading {
    margin: 2rem 0 0.5rem;
}

.lightbox {
    :global(.yarl__slide) {
        flex-direction: column;
    }

    :global(.yarl__slide_wrapper) {
        padding-top: 1rem;
    }

    :global(.yarl__slide_captions_container) {
        position: static;
        padding: 0.5rem;
    }

    :global(.yarl__slide_description) {
        position: static;
        font-family: var(--font-sans);
        font-size: 0.875rem;
    }
}
