@use "./node_modules/include-media/dist/_include-media.scss" as *;
.outer {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
}

.outer + .outer {
    position: relative;
    margin-top: 1.5rem;
    padding-top: 1.5rem;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        max-width: 160px;
        height: 2px;
        background: rgba(var(--textcolor-rgb) / 5%);
    }
}

.hgroup {
    display: flex;
    flex-direction: column-reverse;
    gap: 0.25rem;

    .title {
        font-size: 1.125rem;
        line-height: 1.2;
    }
}

.link {
    h1 {
        color: rgba(var(--linkcolor-rgb) / 100%);
        text-decoration: underline;
        text-decoration-color: rgba(var(--linkcolor-rgb) / 33%);
        transition: text-decoration-color var(--transition-duration);
    }

    &:hover h1 {
        text-decoration-color: rgba(var(--linkcolor-rgb) / 100%);
    }
}
