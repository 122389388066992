@use "./node_modules/include-media/dist/_include-media.scss" as *;
$input-horizontal-padding: 0.875rem;
$icon-size: 1rem;
$icon-x-position: 0.75rem;

.outer {
    overflow: hidden;
    position: relative;
}

.inputWithIcon {
    position: relative;
}

.input {
    width: 100%;
    background: #222;
    padding: 0.5rem $input-horizontal-padding;
    font-family: var(--font-sans);
    outline: none;
    cursor: pointer;

    &::placeholder {
        letter-spacing: 0.01em;
        font-style: italic;
        color: rgb(255 255 255 / 66%);
    }
}

.icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: $icon-x-position;
    color: #a3a3a3;
    width: $icon-size;
    height: $icon-size;
    pointer-events: none;
}

.select {
    position: absolute;
    top: 0;
    left: 0;
    appearance: none;
    width: 100%;
    padding: 0.5rem;
    opacity: 0;
    background: #222;
}

.ruler {
    position: absolute;
    left: -9999px;
    top: 0;
    height: 0;
    overflow: visible;
    visibility: hidden;
    white-space: nowrap;
    padding: 0 $icon-size + $icon-x-position * 2 0 $input-horizontal-padding;
}
