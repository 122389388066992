@use "./node_modules/include-media/dist/_include-media.scss" as *;
.results {
    opacity: 1;
    transition: opacity 1000ms ease-out;
}

.resultsLoading {
    opacity: 0.66;
}

.title,
.origTitle {
    line-height: 1.4;
    text-wrap: balance;
}

.origTitle {
    opacity: 0.75;
}

.list {
    display: flex;
    flex-direction: column;
    gap: 0;
    padding: 0.125rem 0;

    .item {
        border-bottom: 2px solid rgba(255 255 255 / 5%);

        &:last-child {
            border-bottom: none;
        }
    }

    .link {
        display: flex;
        flex-direction: column;
        gap: 0.25rem;
        padding: 1rem 0.5rem;
        transition: background-color var(--transition-duration);

        &:hover {
            background-color: rgba(255 255 255 / 5%);
        }
    }

    .title {
        + .origTitle {
            margin-top: -0.25rem;
        }
    }
}

.listImg {
    --columns: 1;

    display: grid;
    grid-template-columns: repeat(var(--columns), minmax(0, 1fr));
    gap: 1rem;
    justify-content: center;
    padding: 1.5rem 0;

    @include media('>phone') {
        --columns: 1;
    }
    @include media('>tablet') {
        --columns: 2;
    }
    @include media('>desktop') {
        --columns: 3;
    }
    @include media('>=1440px') {
        --columns: 4;
    }

    /* stylelint-disable-next-line no-descending-specificity */
    .link {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        transition: all var(--transition-duration);
        background: rgba(255 255 255 / 4%);
        border-radius: 0.25rem;
        padding: 0.75rem;
        height: 100%;

        &:hover {
            background: rgba(255 255 255 / 8%);
        }
    }

    .img {
        display: block;
        width: 100%;
        height: auto;
        aspect-ratio: 1 / 1;
        object-fit: contain;
        opacity: 0;
        max-width: 100vw;
        transition: opacity var(--transition-duration) ease-in;

        @include media('<=phone') {
            max-width: 100%;
            height: auto;
        }
    }

    .imgLoaded {
        opacity: 1;
    }

    .title {
        padding-top: 1rem;
    }
}

.label {
    opacity: 0.75;
}

.noImage {
    flex: 0 0 240px;
    border: 1px solid rgb(50 50 50 / 25%);
    padding: 1rem;
}

.transitionEnter,
.transitionEnterActive,
.transitionEnterDone {
    opacity: 1;
}

.transitionExit {
    opacity: 1;
}

.transitionExitActive {
    opacity: 0;
    transition: opacity 250ms ease-out;
}

.transitionExitDone {
    opacity: 0;
}
