@use "./node_modules/include-media/dist/_include-media.scss" as *;
.outer {
    width: 100%;
}

.imageWrap {
    position: relative;
    width: 100%;
    height: calc(100dvh - var(--header-height) - 4rem);
    background: rgba(0 0 0 / 20%);
}

.hgroup {
    position: absolute;
    z-index: 3;
    bottom: 1.5rem;
    right: 3rem;
    color: white;
    text-align: right;
    text-shadow:
        0 0.25rem 0.25rem rgba(0 0 0 / 25%),
        0 0 1.5rem rgba(0 0 0 / 44%);

    @include media('<tablet') {
        right: 0;
        width: 100%;
        padding: 0 1rem;
    }

    h1 {
        font-size: calc((58 / 16) * 1rem);

        @include media('<tablet') {
            font-size: calc((32 / 16) * 1rem);
            line-height: 1.2;
            margin-bottom: 0.5rem;
        }
    }

    h2 {
        font-size: calc((28 / 16) * 1rem);

        @include media('<tablet') {
            font-size: calc((20 / 16) * 1rem);
            line-height: 1.2;
        }
    }
}

.content {
    padding-bottom: 3rem;
    text-align: center;
    max-width: 960px;
    margin: 0 auto;
}
