@use "./node_modules/include-media/dist/_include-media.scss" as *;
.outer {
    color: rgb(var(--textcolor-rgb));
    border: 2px solid rgba(var(--textcolor-rgb) / 10%);
    transition:
        background-color var(--transition-duration),
        color var(--transition-duration);

    &:has(.open) {
        --textcolor-rgb: var(--ink-rgb);
        --linkcolor-rgb: var(--link-rgb);

        background: rgb(var(--paper-rgb));

        :global(section::before) {
            background-color: rgba(0 0 0 / 10%);
        }
    }
}

.outer + .outer {
    border-top: none;
}

.toggle {
    display: flex;
    width: 100%;
    align-items: center;
    text-align: left;
    gap: 1rem;
    font-size: 1.125rem;
    padding: 1.5rem 2rem;
    background-color: rgba(255 255 255 / 0%);
    transition: background-color var(--transition-duration);

    &:hover {
        background-color: rgba(255 255 255 / 1%);
    }
}

.inner {
    padding: 0.5rem 2rem 1.5rem 2.25rem;
}

.icon {
    transform: rotate(-90deg);
    transition: transform var(--transition-duration) cubic-bezier(0.4, 0, 0.2, 1);
}

/* open state */
.open {
    .icon {
        transform: rotate(0deg);
    }
}
