@use "./node_modules/include-media/dist/_include-media.scss" as *;
.pre {
    margin-top: 2rem;
    padding: 1rem;
    border: 1px solid rgba(255 255 255 / 20%);
    border-radius: 0.5rem;
    background: rgba(0 0 0 / 20%);
    white-space: pre-wrap;
    overflow-wrap: break-word;
}
