@use "./node_modules/include-media/dist/_include-media.scss" as *;
.footer {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    font-family: var(--font-sans);

    @include media('<desktop') {
        display: block;
    }
}

.pagination {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 0.25rem;
}

.pagInner {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    gap: 0.75rem;
}

.pagButton {
    width: 2.5rem;
    height: 2.5rem;
    display: grid;
    place-content: center;
}

.pagInput {
    background: #222;
    width: auto;
    border-bottom: 2px solid rgb(255 255 255 / 10%);
    padding: 0.75rem 1rem;
}

.pp {
    display: flex;
    align-items: baseline;
    gap: 1rem;

    @include media('<desktop') {
        display: block;
        margin-top: 2rem;
        text-align: center;

        > span {
            margin-left: 1rem;
        }
    }
}
