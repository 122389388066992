@use "./node_modules/include-media/dist/_include-media.scss" as *;
.navUl {
    display: flex;
    gap: 2.5rem;
    justify-content: center;
    margin-bottom: -2px;
}

.navA {
    display: block;
    font-size: 1.0625rem;
    font-weight: 350;
    padding: 0.75rem 0;
    transition: all var(--transition-duration);
}

.navActive {
    font-weight: 600;
    border-bottom-color: rgb(255 255 255 / 40%);
}
