@use "./node_modules/include-media/dist/_include-media.scss" as *;
.main {
    flex: 1;
    padding: 3rem var(--app-gutters);
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    gap: 1rem;
    opacity: 0;
    animation: var(--transition-duration-relaxed) fade-in forwards;
}

@keyframes fade-in {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.noGutter {
    padding-left: 0;
    padding-right: 0;
}

.noPaddingY {
    padding-top: 0;
    padding-bottom: 0;
}

.wrap {
    width: 100%;
    margin: 0 auto;
    grid-column: 1 / span 12;
}

.width960 {
    max-width: 960px;
}

.width1280 {
    max-width: 1280px;
}

.width1440 {
    max-width: 1440px;
}

.col6 {
    @include media('>=desktop') {
        grid-column: 4 / span 6;
    }
}

.col8 {
    @include media('>=desktop') {
        grid-column: 3 / span 8;
    }
}

.col10 {
    @include media('>=desktop') {
        grid-column: 2 / span 10;
    }
}
