@use "./node_modules/include-media/dist/_include-media.scss" as *;
/* stylelint-disable no-descending-specificity */
.listDefault,
.listComma,
.listSlash {
    a {
        color: rgb(var(--linkcolor-rgb));
        text-decoration: underline;
        text-decoration-color: rgba(var(--linkcolor-rgb) / 33%);
        transition: text-decoration-color var(--transition-duration);

        &:hover {
            text-decoration-color: rgba(var(--linkcolor-rgb) / 100%);
        }
    }
}

.listDefault {
    li {
        margin-bottom: 0.25rem;

        &:last-child {
            margin-bottom: 0;
        }
    }
}

.listComma {
    li {
        display: inline-block;

        &::after {
            content: ', ';
        }
    }
}

.listSlash {
    li {
        display: inline-block;
        padding-right: 1rem;
        position: relative;

        &::after {
            content: '/';
            position: absolute;
            right: 0;
            top: 0;
            width: 1rem;
            text-align: center;
        }

        &:last-child {
            &::after {
                content: '';
            }
        }
    }
}

.listTeaser {
    li {
        border-bottom: 2px solid rgba(255 255 255 / 5%);
        padding: 1rem 0;

        &:first-child {
            padding-top: 0;
        }

        &:last-child {
            border-bottom: none;
            padding-bottom: 0;
        }
    }
}
