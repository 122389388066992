@use "./node_modules/include-media/dist/_include-media.scss" as *;
.header {
    display: flex;
    justify-content: space-between;
    align-items: baseline;

    @include media('<desktop') {
        display: block;
    }
}

.count {
    font-size: 1.125rem;

    @include media('<desktop') {
        text-align: center;
    }
}

.sort {
    font-family: var(--font-sans);
    display: flex;
    align-items: baseline;
    gap: 1rem;

    @include media('<desktop') {
        text-align: center;
        margin-top: 1rem;
        display: block;

        > span {
            margin-left: 1rem;
        }
    }
}

.resetFilters {
    padding: 0.5rem 0.875rem;
    background: rgb(34 34 34);
    display: inline-block;
    margin-left: 1rem;
    cursor: pointer;
    font-size: 0.875rem;
    font-family: var(--font-sans);
}

.resetFiltersIcon {
    vertical-align: middle;
    width: 0.875rem;
    height: 0.875rem;
    margin-left: 0.25rem;
}
